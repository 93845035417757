import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = "http://localhost:3000";
    },

    saveTokenAndSetHeader(token) {
        if(token) {
            JwtService.saveToken(token);
            this.setHeader();
        }
    },

    /**
     * Set the default HTTP request headers
     */
    setHeader() {
        Vue.axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${JwtService.getToken()}`;
    },

    query(resource, params) {
        return Vue.axios.get(resource, params);
    },

    /**
     * Send the GET HTTP request
     * @param resource
     * @param config
     * @returns {*}
     */
    get(resource, config) {
        return Vue.axios.get(`${resource}`, config);
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param data
     * @param config
     * @returns {*}
     */
    post(resource, data, config) {
        return Vue.axios.post(`${resource}`, data, config);
    },

    /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    update(resource, slug, params) {
        return Vue.axios.put(`${resource}/${slug}`, params);
    },

    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, params) {
        return Vue.axios.put(`${resource}`, params);
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
    delete(resource) {
        return Vue.axios.delete(resource).catch((error) => {
            throw new Error(`ApiService ${error}`);
        });
    },
};

export default ApiService;