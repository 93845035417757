import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: () => {
        // Redirect to MTA website (rather than showing a 404 page)
        window.location.href="https://mtansw.com.au";
      },

      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/wizard",
          name: "wizard",
          component: () => import("@/view/pages/wizard/Wizard.vue"),
          children: [
            {
              path: "membership-details-form",
              name: "membership-details-form",
              component: () =>
                import("@/view/pages/wizard/membership-details-form/MembershipDetailsForm")
            },
            {
              path: "payment",
              name: "payment",
              component: () =>
                  import("@/view/pages/wizard/payment/PaymentPage")
            },
          ]
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
