import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;

// Vue 3rd party plugins
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import * as VeeValidate from "vee-validate";
import vuetify from "@/core/plugins/vuetify";
import { required, email, oneOf, max_value, max, alpha_num, is, numeric, regex } from "vee-validate/dist/rules";
import VueCurrencyFilter from "vue-currency-filter";
import VueCurrencyInput from "vue-currency-input";
import VueFilterDateFormat from "@vuejs-community/vue-filter-date-format";
import VueFilterDateParse from "@vuejs-community/vue-filter-date-parse";
import VueSignaturePad from "vue-signature-pad";

const INVALID_EMAIL_PREFIXES = [ 'abuse@','accounting@','accounts@','adm@','admin@','administration@','administrator@',
  'admissions@','ads@','all@','answers@','anti-spam@','antispam@','asdf@','billing@','ceo@','comments@','compliance@',
  'contact@','contactus@','customer@','customercare@','customerservice@','database@','decliend@','decline@','declined@',
  'denied@','designer@','devnull@','director@','dns@','email@','employment@','enquiry@','enquiries@','everyone@','fbl@','feedback@',
  'finance@','ftp@','general@','hello@','helpdesk@','home@','hostmaster@','hr@','info@','information@','inoc@',
  'investorrelations@','ispfeedback@','ispsupport@','jobs@','lawyer@','lawyers@','legal@','list@','list-request@',
  'mail@','mailbox@','mail-daemon@','maildaemon@','mail-deamon@','manager@','managers@','marketing@','me@','media@',
  'mediarelations@','mkt@','news@','noc@','noreplies@','no-reply@','noreply@','noemail@','nospam@','nothanks@','null@',
  'office@','operations@','orders@','phish@','phishing@','post@','postbox@','postmaster@','prepress@','president@',
  'press@','privacy@','purchasing@','qwer@','qwert@','qwerty@','reception@','refuse@','refused@','registrar@','remove@',
  'request@','reservations@','returns@','root@','sales@','secretary@','security@','service@','services@','shop@','spam@',
  'staff@','studio@','subscribe@','support@','sysadmin@','tech@','undisclosed-recipients@','unsubscribe@','usenet@',
  'users@','uucp@','web@','webmaster@','welcome@','www@'];

Vue.use(VueCurrencyInput, {
  globalOptions: {
    currency: { prefix: "$ " },
    allowNegative: false,
    distractionFree: false,
    precision: 2
  }
});

Vue.use(VueCurrencyFilter, {
  symbol: "$",
  thousandsSeparator: ",",
  fractionCount: 2,
  fractionSeparator: ".",
  symbolPosition: "front",
  symbolSpacing: true
});

VeeValidate.extend("max_value", {
  ...max_value,
  params: ["max"],
  message: "Invalid input"
});

VeeValidate.extend("max", {
  ...max,
  params: ["length"],
  message: "Maximum {length} characters allowed"
});

VeeValidate.extend("email", {
  ...email,
  message: "Invalid email address"
});

VeeValidate.extend("valid-email", {
  validate: function(value) {
    let result = true;
    if(value) {
      INVALID_EMAIL_PREFIXES.forEach(prefix => {
        result = result && !value.toLowerCase().startsWith(prefix);
      });
    }
    return result;
  },
  message: "Email address not allowed"
});

VeeValidate.extend("required", {
  ...required,
  message: "This field is required"
});

VeeValidate.extend("phone-regex", {
  ...regex,
  message: "Invalid phone number"
});

VeeValidate.extend("required-action", {
  ...required,
  message: "This action is required"
});

VeeValidate.extend("oneOf", {
  ...oneOf,
  message: "Choose one"
});

VeeValidate.extend("alpha_num", {
  ...alpha_num,
  message: "This field can only contain alphabets and numbers"
});

VeeValidate.extend("numeric", {
  ...numeric,
  message: "This field can only contain numbers"
});

VeeValidate.extend("is", {
  ...is,
  params: ["value"],
  message: "Value must be {value}"
});

Vue.use(VueFilterDateParse);
Vue.use(VueFilterDateFormat);

Vue.use(VeeValidate);
Vue.component("ValidationProvider", VeeValidate.ValidationProvider);
Vue.component("ValidationObserver", VeeValidate.ValidationObserver);

Vue.use(VueSignaturePad);

// API service init
ApiService.init();

// Services used by various wizards - don't delete these lines
// Vue.prototype.$apiService = ApiService;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
